<template>
  <div>
    <div class="bg" v-if="loginbg">
      <!-- 登录 -->
      <div class="logintop" v-if="loginindex">
        <div class="logintitleon">
          用户登录
        </div>
        <div>
          <input
            type="text"
            v-model="loginfrom.uname"
            placeholder="输入手机号"
          />
          <input
            type="password"
            v-model="loginfrom.upwd"
            placeholder="输入密码"
          />
        </div>
        <div>
          <van-button class="loginbutton" round type="info" @click="login()"
            >登 录</van-button
          >
          <van-button
            class="loginbutton"
            style="margin-top: 15px"
            round
            @click="quit()"
            >返 回 首 页</van-button
          >
        </div>
        <div style="margin: 15px; color: #1989fa" @click="enroll()">注册</div> 
      </div>
      <!-- 注册 一 -->
      <div class="logintop" v-if="enrollone">
        <div class="logintitleon">
          用户注册
        </div>
        <div class="enrollfromstyle">
          <input
            type="text"
            v-model="enrollOnefrom.Mobile"
            placeholder="输入手机号"
          />
          <input
            type="text"
            v-model="enrollOnefrom.ShortMsg"
            placeholder="输入手机验证码"
          />
          <button v-if="show" @click="ValidCodeRandom()" class="shortmsgon">
            获取短信验证码
          </button>
          <button v-if="!show" class="shortmsgon">
            {{ count }} 秒后重新获取
          </button>
          <input
            type="text"
            v-model="enrollOnefrom.ValidCode"
            placeholder="输入图像验证码"
          />
          <img :src="imgcode" class="imgcode" alt="" />
        </div>
        <div>
          <van-button
            class="loginbutton"
            round
            type="info"
            @click="RegisterOnefrom()"
            >注 册</van-button
          >
        </div>
        <div style="margin: 15px; color: #1989fa" @click="loginindexshow">
          返回登录页面
        </div>
      </div>
    </div>
    <div>
      <!-- 注册 二 -->
      <div class="logintop" style="bottom: 0;" v-if="enrollindex">
        <div style="font-size: 24px; font-weight: 700;">
          用户信息
        </div>
        <div class="enrollfromstyle">
          <input
            type="text"
            v-model="enrollfrom.mobile"
            placeholder="输入手机号"
          />
          <input
            type="password"
            v-model="enrollfrom.password"
            placeholder="输入密码"
          />
          <input
            type="password"
            v-model="enrollfrom.upwd"
            placeholder="再次输入密码"
          />
          <input type="text" v-model="enrollfrom.name" placeholder="输入姓名" />
          <input
            type="text"
            @click="sexshow = true"
            v-model="enrollfrom.sexname"
            placeholder="选择性别"
            readonly="readonly"
          />
          <van-popup v-model="sexshow" position="bottom" get-container="body">
            <van-picker
              show-toolbar
              :columns="sexlist"
              value-key="Name"
              @cancel="sexshow = false"
              @confirm="onConfirm"
            >
            </van-picker>
          </van-popup>
          <input
            type="text"
            v-model="enrollfrom.email"
            placeholder="输入邮箱"
          />
          <input
            type="text"
            v-model="enrollfrom.locatedCity"
            placeholder="输入现住址"
          />
          <input
            type="text"
            @click="dateshow = true"
            v-model="enrollfrom.yearofbirth"
            placeholder="选择出生年月"
            readonly="readonly"
          />
          <van-popup v-model="dateshow" position="bottom" get-container="body">
            <van-datetime-picker
              show-toolbar
              :columns="sexlist"
              v-model="enrollfrom.yearofbirth"
              type="date"
              title="选择出生年月"
              :min-date="minDate"
              :max-date="maxDate"
              @cancel="dateshow = false"
              @confirm="ondateConfirm"
            >
            </van-datetime-picker>
          </van-popup>
          <input
            type="text"
            @click="workingYearshow = true"
            v-model="enrollfrom.workingYear"
            placeholder="选择工作时间"
            readonly="readonly"
          />
          <van-popup
            v-model="workingYearshow"
            position="bottom"
            get-container="body"
          >
            <van-datetime-picker
              show-toolbar
              :columns="sexlist"
              v-model="enrollfrom.workingYear"
              type="date"
              title="选择工作时间"
              :min-date="minDate"
              :max-date="maxDate"
              @cancel="workingYearshow = false"
              @confirm="workingYeardate"
            >
            </van-datetime-picker>
          </van-popup>
          <input
            type="text"
            v-model="enrollfrom.desiredjob"
            placeholder="输入期待职位"
          />
          <input
            type="text"
            v-model="enrollfrom.desiredSalary"
            placeholder="输入期待薪资"
          />
        </div>
        <div>
          <van-button
            class="loginbutton"
            round
            type="info"
            @click="RegisterOverfrom()"
            >提 交</van-button
          >
        </div>
        <!-- <div style="margin:15px; color:#1989fa" @click="loginindexshow">返回登录页面</div> -->
      </div>
    </div>
  </div>
</template>
<script>
import {
  CheckSeeker,
  RegisterOver,
  SendValidedMsg,
  GenerateImgValidCode,
  RegisterOne,
} from "@/api/ResumeJS";
import { getToken, setToken } from "@/utils/auth";
import config from "@/config";
import Vue from "vue";
import { Toast } from "vant";
Vue.use(Toast);
export default {
  data() {
    return {
      loginfrom: {
        //登录提交数据
        uname: "",
        upwd: "",
      },
      enrollfrom: {
        //注册
        mobile: "", //手机号、
        password: "", //登录密码、
        name: "", //姓名、
        sex: "", //性别、微信微信号、
        email: "", //邮箱、
        locatedCity: "", //猎头和HR注册为公司注册地，注册者为现住地、、
        yearofbirth: "", //出生年份（出生者）、
        workingYear: "", //工作时间、
        desiredjob: "", //期望职位、
        desiredSalary: "", //期望职业，单位：元/月、工作状态-状态
      },
      enrollOnefrom: {
        //注册第一步
        ImgVerifier: "", //图片验证者，时间戳、
        ValidCode: "", //图片验证码、
        Mobile: "", //手机号、
        ShortMsg: "", //短信验证码;
      },
      yearofbirth: "", //时间
      loginbg:true,
      loginindex: true, //登录页面
      enrollindex: false, //注册第二步
      enrollone: false, //注册第一步
      dateshow: false, //出生年月
      sexshow: false, //性别
      workingYearshow: false, //工作时间
      minDate: new Date(1970, 0), //
      maxDate: new Date(),
      verifier: "", //时间戳
      imgcode: "", //图片验证码
      sexlist: [
        { id: "1", Name: "男" },
        { id: "2", Name: "女" },
      ], //性别选择器
      show: true,
      count: "",
      timer: null,
    };
  },
  created() {
    var token = getToken();
    if(token != undefined){
      this.$router.push("/list/jobhunter");
    }
    this.GenerateImg();
  },
  methods: {
    login() {
      //登录
      if (this.loginfrom.uname == "") {
        Toast("手机号不能为空");
        return false;
      }
      if (this.loginfrom.upwd == "") {
        Toast("密码不能为空");
        return false;
      }
      CheckSeeker(this.loginfrom).then((res) => {
        console.log(res);
        if (res.data.code == 0) {
          Toast.success("登录成功");
          setToken(res.data.data.token);
          this.$router.push("/list/jobhunter");
        } else {
          Toast.fail(res.data.msg);
        }
      });
    },
    quit() {
      this.$router.push("/list");
    },
    // 注册按钮
    enroll() {
      this.loginindex = false;
      this.enrollone = true;
    },
    loginindexshow() {
      this.loginindex = true;
      this.enrollone = false;
      this.enrollindex = false;
      this.loginindex = true;
    },
    // 注册 第一步
    RegisterOnefrom() {
      this.enrollOnefrom.ImgVerifier = this.verifier;
      RegisterOne(this.enrollOnefrom).then((res) => {
        console.log(res);
        if (res.data.code == 0) {
          Toast.success("注册成功!");
          this.enrollindex = true;
          this.enrollone = false;
          this.loginindex = false;
          this.enrollfrom.mobile = this.enrollOnefrom.Mobile;
        } else {
          Toast.fail(res.data.msg);
        }
      });
    },
    // 注册 第二步
    RegisterOverfrom() {
      if (this.enrollfrom.mobile == "") {
        Toast.fail("手机号不能为空!");
        return false;
      }
      if (this.enrollfrom.password == "") {
        Toast.fail("密码不能为空!");
        return false;
      }
      if (this.enrollfrom.password !== this.enrollfrom.upwd) {
        Toast.fail("两次密码不同!");
        return false;
      }
      if (this.enrollfrom.desiredSalary == "") {
        Toast.fail("期待薪资不能为空!");
        return false;
      }
      if (this.enrollfrom.name == "") {
        Toast.fail("姓名不能为空!");
        return false;
      }
      if (this.enrollfrom.email == "") {
        Toast.fail("邮箱不能为空!");
        return false;
      }
      if (this.enrollfrom.yearofbirth == "") {
        Toast.fail("出生年份不能为空!");
        return false;
      }
      if (this.enrollfrom.workingYear == "") {
        Toast.fail("工作时间不能为空!");
        return false;
      }
      if (this.enrollfrom.desiredjob == "") {
        Toast.fail("期待职位不能为空!");
        return false;
      }
      if (this.enrollfrom.desiredSalary == "") {
        Toast.fail("期待薪资不能为空!");
        return false;
      }

      RegisterOver(this.enrollfrom).then((res) => {
        console.log(res);
        if (res.data.code == 0) {
          Toast.success("注册成功!");
          this.loginindexshow();
        } else {
          Toast.fail(res.data.msg);
        }
      });
    },
    // 获取短信验证码
    ValidCodeRandom() {
      if (this.enrollOnefrom.Mobile == "") {
        Toast.fail("请输入手机号!");
        return false;
      }
      var MobileNum = this.enrollOnefrom.Mobile;
      const TIME_COUNT = 60;
      if (!this.timer) {
        this.count = TIME_COUNT;
        this.show = false;
        SendValidedMsg({ MobileNum: MobileNum }).then((res) => {
          console.log(res);
          if (res.data.code == 0) {
            Toast.success("验证码已发送!");
            this.timer = setInterval(() => {
              if (this.count > 0 && this.count <= TIME_COUNT) {
                this.count--;
              } else {
                this.show = true;
                clearInterval(this.timer);
                this.timer = null;
              }
            }, 1000);
          } else {
            Toast.fail(res.data.msg);
          }
        });
      }
    },

    // 获取图片验证码
    GenerateImg() {
      this.verifier = new Date().getTime();
      var src =
        config.apiRequestUrl +
        GenerateImgValidCode({ verifier: this.verifier });
      this.imgcode =
        config.apiRequestUrl +
        "/Plat/GenerateImgValidCode?verifier=" +
        this.verifier;
    },
    // 性别
    onConfirm(value, index) {
      console.log("确认按钮");
      console.log(value.id);
      this.sexshow = false;
      this.enrollfrom.sex = value.id;
      this.enrollfrom.sexname = value.Name;
    },
    // 出生年月
    ondateConfirm(val) {
      this.enrollfrom.yearofbirth = this.dateformat(val);
      this.dateshow = false;
    },
    // 工作时间
    workingYeardate(val) {
      this.enrollfrom.workingYear = this.dateformat(val);
      this.workingYearshow = false;
    },
    // 日期格式化
    dateformat(val) {
      let year = val.getFullYear();
      let month = val.getMonth() + 1;
      let day = val.getDate();
      if (month >= 1 && month <= 9) {
        month = `0${month}`;
      }
      if (day >= 1 && day <= 9) {
        day = `0${day}`;
      }
      return `${year}-${month}-${day}`;
    },
  },
};
</script>
<style>
.logintop {
  margin: 30px;
  position: relative;
  bottom: -300px;
}
/* .logintop div:nth-child(1){
    font-size: 24px;
    font-weight: 700;
} */
.logintop div:nth-child(2) {
  margin-top: 35px;
}
.logintop div:nth-child(2) input {
  border-radius: 50px;
  width: 88%;
  height: 50px;
  background: #f5f4f4;
  margin: 15px 0px;
  padding-left: 35px;
  color: #000;
  border: none;
  box-shadow: 1px 1px 1px #f5f4f4;
}
.logintop div:nth-child(3) {
  margin-top: 35px;
}
.logintop div:nth-child(3) .loginbutton {
  width: 100%;
  font-size: 16px;
  font-weight: 400;
  height: 50px;
}
.logintop .enrollfromstyle input {
  height: 30px !important ;
  margin: 7px 0px !important;
}
.shortmsgon {
  border: none;
  position: absolute;
  right: 0px;
  width: 130px;
  top: 54px;
  border-left: 1px solid;
  height: 32px;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  font-size: 14px;
  background: #f5f4f4;
}
.imgcode {
  position: absolute;
  height: 32px;
  top: 100px;
  right: 0px;
  width: 90px;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
}
.bg {
  position: relative;
  top: -40px;
  background: url("../../assets/login.png");
}
.logintitleon{
  position: fixed;
  top: 15px;
  font-size: 24px; 
  font-weight: 700; 
  color: #fff;
}
</style>